<template>
  <div class="Software">
    <div  class="div1">
      <div class="wrap-mask">
        <div style="width: 85%;height:600px;margin: 40px auto;padding-top: 7%;text-align: left;font-size: 15px;text-align: center;">
          <div>
            <img  src="@/assets/logo_v_show.png"  height="300px" style="margin-left:8px;"/>
          </div>
          <h1>欢迎使用一物一码企业管理系统！</h1>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
export default{
  name:'WorkHomeWelcome',
  data(){
    return {
      companyid:this._self.$root.$store.state.LogonInfo.companyid
    }
  },
  mounted(){
    console.log("欢迎页面挂载")
    this.queryModelData()
  },
  methods:{
    queryModelData(){
      axios.all([
          axios.post("/api/tech/master/modelver/query",{companyId:this._self.$root.$store.state.LogonInfo.companyid}),
          axios.post('/api/tech/master/model/query',{companyId:this._self.$root.$store.state.LogonInfo.companyid}),
          axios.post("/api/tech/master/modelver/getmodellist",{companyId:this.companyid}),
          axios.post("/api/MasterConfig/get"),
          axios.post("/api/certificatetemplate/query",{
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    pageNum:1,
                    pageSize:400
                })
      ])
      .then(axios.spread(function (modelVerRsp, modelRsp,modellistRsp,erpconfigRsp,cerRsp) {
          // 上面几个请求都完成后，才执行这个回调方法
          if(modelVerRsp.data.resultCode ==0){
            console.log("欢迎页面挂载读取modelver",modelVerRsp.data.data)
            this.$store.commit('changeMasterModelVerList',modelVerRsp.data.data)
          }
          else{
              this.$Message.error({content:modelVerRsp.data.msg, duration:3})
          }

          if(modelRsp.data.resultCode ==0){
            console.log("欢迎页面挂载读取model",modelRsp.data.data)
              this.$store.commit('changeMasterModelList',modelRsp.data.data)
          }
          else{
              this.$Message.error({content:modelRsp.data.msg, duration:3})
          }

          if(modellistRsp.data.resultCode ==0){
            console.log("欢迎页面读取车型选择列表",modellistRsp.data.data)
            var MasterModelDatalist = modellistRsp.data.data
            this.$store.commit("changeModelChooseListInfo",MasterModelDatalist)
          }
          else{
              this.$Message.error({content:modelRsp.data.msg, duration:3})
          }

          if(erpconfigRsp.data.resultCode ==0){
            console.log("欢迎页面读取ERP配置",erpconfigRsp.data.data)
            var erpConfig = erpconfigRsp.data.data
            if(erpConfig == null){
              erpConfig = {
                finCkSaleplan: 'false',
                finCkSaleorderPay:'true',
                purchaseHuaboValue: 50
              }
            }
            this.$store.commit("changeErpConfig",erpConfig)
          }
          else{
              this.$Message.error({content:erpconfigRsp.data.msg, duration:3})
          }

          //合格证
          if(cerRsp.data.resultCode ==0){
              var rspdata = cerRsp.data.data.data
              console.log("欢迎页面读取合格证数据结果",rspdata)
              let CertificateTemplateList=[]
              for(var item=0;item<rspdata.length;item++){
                  var cer={value:null,certificateid:null,label:null,certificateno:null,standard:null}

                  cer.value=item+1
                  cer.certificateid=rspdata[item].id
                  cer.label=rspdata[item].certificatealias
                  cer.certificateno=rspdata[item].certificateno
                  cer.standard= (rspdata[item].standardtype == 1 ? '国标':'企标')
                  CertificateTemplateList.push({cerdisplay:cer,cerfulldata:rspdata[item]})
              }
              this.$store.commit('changeCertificateList',CertificateTemplateList)
              this.$Message.success({content:'合格证数据同步完成', duration:3})

              console.log("欢迎页面合格证数据列表",CertificateTemplateList)
          }
          else{
              this.$Message.error({content:res.data.data.msg, duration:3})
          }
      }.bind(this)));
    }
  }
}
</script>
<style scoped>
  .Software .div1 {
    position: relative;
    background: url("~@/assets/welcome.jpg");
    background-size:cover;
  }

  .Software .wrap-mask {
    background-color: rgba(185, 196, 233, 0.9);
    width: 100%;
    height: 100%;
  }
</style>